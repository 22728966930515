@tailwind base;
@tailwind components;
@tailwind utilities;

/* ql */
/* .ql-editor {
  overflow-wrap: break-word;
}

.ql-editor p, .ql-editor ol, .ql-editor ul, .ql-editor pre, .ql-editor blockquote, .ql-editor h1, .ql-editor h2, .ql-editor h3, .ql-editor h4, .ql-editor h5, .ql-editor h6 {
  word-break: break-all;
} */

/* log rocket */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
}

header {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  color: #212529;
}

.nav-area {
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px 20px;
}

.logo {
  text-decoration: none;
  font-size: 25px;
  color: inherit;
  margin-right: 20px;
}

.menus {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
}

.menu-items {
  position: relative;
  font-size: 14px;
}

.menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}

.menu-items button {
  display: flex;
  align-items: center;
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
}

button span {
  margin-left: 3px;
}

.menu-items > a, .menu-items button {
  text-align: left;
  padding: 0.7rem 1rem;
}

/* .menu-items a:hover,
.menu-items button:hover {
  background-color: #f2f2f2;
} */

.arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
  @apply text-primary-green;
}

.dropdown {
  position: absolute;
  right: 0;
  left: auto;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 0.875rem;
  z-index: 9999;
  min-width: 10rem;
  padding: 0.5rem 0;
  list-style: none;
  background-color: #fff;
  border-radius: 0.5rem;
  display: none;
}

.dropdown.show {
  display: block;
}

.dropdown .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;
}

/* content */

.content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 3rem 20px;
}

.content h2 {
  margin-bottom: 1rem;
}

.content a {
  color: #cc3852;
  margin-right: 10px;
}

/* end log rocket */

.navbar_list {
  @apply container flex flex-col justify-center gap-6 text-primary-green text-lg;
}

.navbar_list_desktop {
  @apply text-white font-semibold text-xl;
}

.navbar_list_desktop_child {
  @apply bg-white text-primary-green mt-2 flex flex-col;
}

.navbar_list_desktop_child li,
.navbar_list_desktop_child_inner li {
  @apply px-6 py-2 whitespace-nowrap;
}

.navbar_list_desktop_child > li:not([class='button']):hover,
.navbar_list_desktop_child_inner > li:hover {
  @apply bg-primary-green/50;
}

.navbar_list_desktop_child_inner {
  @apply pt-2;
}

.navbar_dropdown {
  @apply flex items-center gap-2 w-fit;
}

.navbar_toggle {
  @apply overflow-hidden transition-all duration-700;
}

.open_nav {
  @apply translate-x-0;
}

.close_nav {
  @apply translate-x-full;
}

.navbar_scrolled_down {
  @apply bg-primary-green;
}

.bg_gradient {
  background: linear-gradient(
    135deg,
    #8dcb86 0%,
    #29b6b3 35.93%,
    #38b9ac 75.59%,
    #8dcb86 100%
  );
}

.menu_container {
  @apply fixed top-0 overflow-y-auto lg:overflow-visible transition-all duration-700 h-full lg:h-auto w-full lg:w-auto left-0 lg:left-auto;
}

.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.modal::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  @apply bg-black/20;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide_scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide_scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.admin_title {
  @apply uppercase font-bold text-primary-green
}

.input_title {
  @apply font-bold uppercase text-black mb-2;
}

.berita_table td {
  padding: 0.75rem 0;
}